.Container {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  height: 1.5rem;
}

.LeftContainer {
  display: flex;
  flex-grow: 2.5;
  flex-basis: 0;
  min-width: 0;
}

.RightContainer {
  display: flex;
  flex-grow: 3;
  flex-basis: 0;
  justify-content: space-between;
}
.IconContainer {
  margin-right: 0.25rem;
  align-self: center;
  display: flex;
  cursor: pointer;
}

.Icon {
  font-size: 16px !important;
}
.Text {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
  text-align: center;
}

.ToolTipOverflownText {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  white-space: nowrap;
}

.TextContainer {
  align-self: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PriceTextContainer {
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
}

.infoIcon {
  opacity: 0.7;
  align-self: center;
  margin-left: 0.5rem;
  width: 0.85rem !important;
}
.ToolTipWrapper {
  padding: 0.5rem;
}
.ToolTipRightContainer {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  max-width: 100%;
  display: flex;
}
.ToolTipTitleText {
  font-weight: 600;
  font-size: 16px !important;
  margin-bottom: 1rem;
}
.ToolTipValueText {
  font-weight: 100;
  margin-bottom: 1rem;
  font-size: 16px;
  margin-left: 1rem;
  text-align: right;
}
.SubscriptionNameContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.SubscriptionName {
  font-weight: 600;
  font-size: 16px;
}

.PowerExpiry {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}
.Power {
  display: flex;
}
.PowerText {
  font-size: 12px;
  font-weight: 500;
}
.PowerValue {
  font-size: 12px;
  font-weight: 100;
  margin-left: 0.5rem;
}

.Hours {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0.125rem;
}
.HoursText {
  font-size: 11px;
  font-weight: 100;
}

.LineGraph {
  width: 100%;
  height: 0.75rem;
  display: flex;
}
.GraphInfo {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 0.25rem;
}
.Box {
  width: 0.5rem;
  height: 0.5rem;
  align-self: center;
  margin-right: 0.25rem;
}
.Box {
  width: 0.5rem;
  height: 0.5rem;
  align-self: center;
  margin-right: 0.5rem;
  border-radius: 2px;
}
.CurrentTariffText {
  font-size: 12px;
  font-weight: 100;
}
.Tariffs {
  font-weight: 600;
  font-size: 14px;
}
.TariffsContainer {
  margin-top: 0.5rem;
}
.TariffItem {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.TariffCell {
  flex: 1;
  flex-grow: 1.2;
  display: flex;
}
.TariffCellMiddle {
  flex: 1;
  flex-grow: 1;
  text-align: center;
}
.BoxTariff {
  min-width: 0.5rem;
  min-height: 0.5rem;
  align-self: center;
  margin-left: 0.25rem;
  border-radius: 2px;
}
