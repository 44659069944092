.Container {
  display: flex;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 2.5rem;
}
.OngoingTextContainer {
  align-self: center;
  height: 100%;
  display: flex;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.DetailsContainer {
  align-self: center;
  width: 100%;
  height: 100%;
  display: flex;
}
.OngoingText {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  align-self: center;
  text-align: center;
  width: 100%;
}
.TokenContainer {
  display: flex;
  height: 100%;
}
.CreditCardIcon {
  display: flex;
  margin-left: 1.313rem;
  align-items: center;
}
.Text {
  margin-left: 0.625rem;
  align-self: center;
  font-size: 1rem;
  line-height: 1rem;
}
.EnergyContainer {
  display: flex;
  align-self: center;
  margin-right: auto;
  margin-left: auto;
}
.FlashContainer {
  align-self: center;
  width: 1.25rem;
  height: 1.25rem;
}
.TimeContainer {
  display: flex;
  height: 100%;
  margin-right: 1rem;
}
.TimerOutlinedIcon {
  align-self: center;
}
.EnergyWrapper {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.RemainingText {
  font-size: 16px;
  margin-left: 0.5rem;
  align-self: center;
  margin-top: 0px !important;
}
.InfoIcon {
  opacity: 0.7;
  align-self: center;
  margin-left: 0.5rem;
  width: 1rem !important;
}
.ToolTipText {
  font-size: 14px;
  align-self: center;
  margin-top: 0.5rem;
}
.ToolTipTextVal {
  font-size: 14px;
}
.Middle a {
  text-decoration: none !important;
  font-size: 1em;
  position: relative;
  transition: all 0.15s;
}

.UserInfo {
  display: flex;
  padding: 0.5rem;
  padding-top: 0;
  width: 400;
}
.InfoLeft {
  flex-grow: 1;
}
.InfoRight {
  flex-grow: 2;
}
.AppUserIdCopyContainer {
  display: flex;
  height: 15px;
  width: 15px;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
}

.IconContainer {
  display: flex;
  transition: opacity 0.5s;
  opacity: 1;
  cursor: pointer;
  width: 16px;
  align-items: center;
  justify-content: center;
}

.visible {
  position: absolute;
  transition: opacity 0.5s;
  opacity: 1;
  width: 0.8rem !important;
  height: 0.8rem;
}

.hide {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.AppUserIdContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
