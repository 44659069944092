.container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  justify-content: center;
}
.accordion {
  border-radius: 5px;
  min-height: 3.5rem;
}

.accordionIconWrapper {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1.2;
  flex-basis: 0;
  align-items: center;
  padding-left: 0.5rem;
}

.accordionTextPower {
  display: flex;
  flex-grow: 1.4;
  flex-basis: 0;
  align-items: center;
}

.accordionTextDuration {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-start;
  align-items: center;
}

.uidContainer {
  padding-left: 0.3rem;
}

.accordionDateContainer {
  display: flex;
  flex-grow: 1.2;
  flex-basis: 0;
  align-items: center;
  justify-content: flex-end;
}

.accordionicon {
  margin-left: 0.5rem;
  align-self: center;
  width: 24px;
}

.accordionIconMoney {
  align-self: center;
}

.flashIcon {
  margin-left: 1rem;
  align-self: center;
  width: 16px;
  height: 16px;
}
.accessTimeIcon {
  margin-top: 0.05rem;
}
.accordionSummary {
  padding-left: 0 !important;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  height: 3.5rem;
}
.accordionLeftText {
  font-size: 0.875rem;
  padding: 0.313rem;
  line-height: 1rem;
  margin: auto;
  padding-left: 0.7rem;
  margin-left: 0;
}

.accordionMiddleText {
  font-size: 0.875rem;
  padding: 0.313rem;
  line-height: 1rem;
  padding-left: 0.7rem;
  margin-left: 0;
}

.accordionDate {
  font-size: 0.875rem;
  text-align: right;
  padding: 0.313rem;
  line-height: 1rem;
  vertical-align: middle;
  margin-left: 0;
  margin-right: 0;
  padding-right: 0.625rem;
}

.accordionRightText {
  font-size: 0.875rem;
  text-align: right;
  padding: 0.313rem;
  line-height: 1rem;
  vertical-align: middle;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
}

.accordionDetails {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}
.expandedLeftText {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: auto;
  padding-left: 0.7rem;
  margin-left: 0;
}

.semiBold {
  font-size: 0.875rem;
  padding: 0.313rem;
  line-height: 1rem;
  margin: auto;
  padding-left: 0.7rem;
  margin-left: 0;
  flex-grow: 1;
  flex-basis: 0;
  font-weight: 600;
}
.expandedRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  float: right;
  padding-right: 1.5rem;
}

.rightDetailsContainer {
  display: flex;
  flex-direction: column;
}

.infoIcon {
  opacity: 0.7;
  align-self: center;
  margin-left: 0.5rem;
  width: 1rem !important;
}
.toolTipText {
  font-size: 14px;
}
.toolTipTextVal {
  font-size: 14px;
}
.userContainer {
  display: flex;
  margin-left: 4px;
}
.userText {
  align-self: center;
  margin-left: 5px;
}
.userInfo {
  display: flex;
  padding: 0.5rem;
  width: 400;
}

.middle a {
  text-decoration: none !important;
  font-size: 1em;
  position: relative;
  transition: all 0.15s;
}

.infoLeft {
  flex-grow: 1;
}
.infoRight {
  flex-grow: 2;
}

.AppUserIdCopyContainer {
  display: flex;
  height: 15px;
  width: 15px;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
}

.IconContainer {
  display: flex;
  transition: opacity 0.5s;
  opacity: 1;
  cursor: pointer;
  width: 16px;
  align-items: center;
  justify-content: center;
}

.visible {
  position: absolute;
  transition: opacity 0.5s;
  opacity: 1;
  width: 0.8rem !important;
  height: 0.8rem;
}

.hide {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.AppUserIdContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.currency {
  font-size: 0.875rem;
  text-align: right;
  padding: 0.313rem;
  line-height: 1rem;
  vertical-align: middle;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  font-weight: 600;
}
