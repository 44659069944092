.SelectPicker {
  width: 14rem;
}

.Container {
  display: flex;
  padding-left: 0.25rem;
  flex-direction: row;
  align-items: center;
}

.IconContainer {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.ComboCCSStyle {
  width: 20px;
}

.IconStyle {
  width: 24px;
}

.Text {
  font-size: 0.75rem;
  font-weight: 400;
  padding-left: 0.625rem;
}

.CurrentTypeContainer {
  border-radius: 5px;
  padding-right: 4px;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 0.5rem;
}

.CurrentTypeText {
  font-size: 0.75rem;
  font-weight: 400;
  padding: 1px;
  padding-right: 2px;
  padding-left: 2px;
}

.LeftContainer {
  display: flex;
  flex: 1;
  align-items: center;
}
